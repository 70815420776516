/* 08.02. Blog Two CSS */

.blog-3 {
    height: 100%;
    margin-bottom: 50px;
    padding-bottom: 50px;
    transition: $transition;
    border-bottom: 1px solid $border-color;

    & .thumbnail {
        position: relative;
        overflow: hidden;
        border-radius: 5px;

        & .image {
            display: block;

            & img {
                width: 100%;
                transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
            }
        }
    }

    & .info {
        position: relative;
        padding: 30px 0 0;

        & .meta {
            font-size: 14px;
            line-height: 27px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-right: -28px;
            margin-bottom: 30px;
            padding-left: 0;
            list-style: none;

            & li {
                margin-right: 28px;

                & i {
                    color: $primary;
                    margin-right: 10px;
                }

                & .avatar {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    border-radius: 50%;
                }

                & a {
                    display: flex;
                    align-items: center;
                }
            }
        }

        & .title {
            font-size: 32px;
            line-height: 44px;
            font-weight: 600;
            margin: 0;
        }

        & .desc {
            margin-top: 30px;
            strong{
                color: $heading-color;
                font-family: 400;
            }
            .block-quote{
                border-left: 4px solid $primary;
                padding-left: 20px;
                margin: 50px 0 44px 48px;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.78;
                max-width: 660px;
                // Responsive
                @media #{$large-mobile}{
                    margin: 25px 0 20px 0px;
                }
            }
        }

        & .entry-post-tags{
            display: flex;
            .tag-label{
                margin: 0 7px 0 0;
            }
        }
    }

    &:hover {
        & .thumbnail {
            & .image {
                & img {
                    transform: scale(1.1);
                }
            }
        }
    }

    @media #{$large-mobile} {
        & .info {
            & .meta{
                margin-bottom: 15px;
            }
            & .title {
                font-size: 24px;
                line-height: 32px;
            }
            & .desc{
                margin-top: 15px;
            }
        }
    }

    @media #{$extra-small-mobile} {
        & .post-share {
            & .label {
                display: none;
            }
        }
    }
}

.media {
    position: relative;

    .list {
        position: absolute;
        z-index: 999;
        left: 0;
        bottom: 100%;
        display: flex;
        visibility: hidden;
        width: auto;
        padding: 0 4px;
        user-select: none;
        transition: $transition;
        transform: translateY(0px);
        white-space: nowrap;
        opacity: 0;
        border-radius: 5px;
        background: $white;
        box-shadow: 0 2px 20px rgba($black, 0.06);
        filter: drop-shadow(0 2px 20px rgba($black, 0.06));

        &::before {
            position: absolute;
            top: 100%;
            left: 20px;
            content: "";
            border-top: 8px solid $white;
            border-right: 9px solid transparent;
            border-left: 9px solid transparent;
        }

        & a {
            display: flex;
            padding: 10px 8px;

            &:hover {
                color: $primary;
            }
        }
    }

    &:hover {
        & .icon {
            color: $white;
            border-color: transparent;
            background-color: $primary;
        }

        & .list {
            visibility: visible;
            transform: translateY(-10px);
            opacity: 1;
        }
    }
}