/* ==========================
    Sidebar Mobile Menu
==============================*/

.show-mobile-menu {
    &.popup-mobile-menu {
        opacity: 1;
        visibility: visible;
        .inner {
            transform: none;
        }
    }
}

.popup-mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    z-index: 99999;
    .mobile-menu-overlay {
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, .5);
    }
    .inner{
        position: relative;
        width: 400px;
        max-width: 80%;
        height: 100%;
        text-align: left;
        transition: all .3s;
        transform: translateX(-100%);
        cursor: default;
        background-color: $secondary;
        background-image: url("images/bg/mobile-bg.jpg");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: top center;
        background-size: cover;
        z-index: 1;

        &::before {
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
            background-color: rgba($secondary, 0.9);
        }

        & .mobile-header{
            display: flex;
            justify-content: space-between;
            height: 85px;
            align-items: center;
            background: #030f27;
            padding: 0 15px 0 30px;
            .logo{
                width: 155px;
            }
            .mobile-close-btn{
                cursor: pointer;
                border: none;
                height: 40px;
                width: 40px;
                flex-shrink: 0;
                position: relative;
                background-color: transparent;

                &::before,
                &::after{
                    position: absolute;
                    top: 19px;
                    left: 8px;
                    content: "";
                    width: 24px;
                    height: 3px;
                    background: $white;
                    transform-origin: 50% 50%;
                    transition: all .3s cubic-bezier(.645,.045,.355,1);
                }
                &::before{
                    transform: rotate(-45deg);
                }
                &::after{
                    transform: rotate(45deg);
                }

                &:hover{
                    &::before ,
                    &::after{
                        transform: none;
                    }
                }
            }
        }
    }
}
.menu-content {
    height: calc(100% - 85px);
    margin: 30px;
    overflow-y: auto;

    .offcanvas-navigation {
        ul {
            padding-left: 0;
            li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                &.menu-item-has-children {
                    .sub-menu {
                        height: 0;
                        opacity: 0;
                        transition: 0.3s;
                        visibility: hidden;
                    }
                    &.active > {
                        .sub-menu {
                            height: 100%;
                            opacity: 1;
                            visibility: visible;
                            padding-left: 0;
                        }
                    }
                }
                a {
                    color: $white;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 10px 0;
                    display: block;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        ul {
            &.sub-menu {
                margin-left: 15px;
                transition: 0.4s;
                li {
                    list-style: none;
                    border: none;
                    a {
                        color: $white;
                        font-weight: 400;
                        &:hover {
                            color: $primary;
                        }
                    }
                    .sub-menu {
                        li {
                            a {
                                font-size: 13px;
                                padding: 5px 0;
                            }
                        }
                    }
                }
            }
            li {
                &.menu-item-has-children {
                    position: relative;
                    display: block;
                    a {
                        display: inline-block;
                    }

                    &.active > {
                        .menu-expand {
                            background-color: rgba($white, 0.2);
                            i {
                                &:before {
                                    transform: rotate(0);
                                }
                            }
                        }
                    }

                    .menu-expand {
                        top: 5px;
                        right: 0;
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                        line-height: 35px;
                        position: absolute;
                        text-align: center;
                        background-color: rgba($white, 0.1);
                        &:hover {
                            background-color: rgba($white, 0.2);
                        }

                        i {
                            display: block;
                            border-bottom: 1px solid $white;
                            position: relative;
                            width: 10px;
                            text-align: center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            &:before {
                                width: 100%;
                                content: "";
                                border-bottom: 1px solid $white;
                                display: block;
                                position: absolute;
                                top: 0;
                                transform: rotate(90deg);
                                transition: 0.4s;
                            }
                        }
                    }
                }
            }
        }
    }
}
