/*----------------------------------------*/
/*  11. Widget CSS
/*----------------------------------------*/

.sidebar-widget-wrapper {
    float: left;
    width: 100%;
    padding-left: 30px;

    @media #{$desktop-device, $tablet-device, $large-mobile} {
        padding-left: 0;
    }
}

.sidebar-widget {
    float: left;
    width: 100%;

    & + .sidebar-widget {
        margin-top: 45px;
    }
}

.sidebar-widget-title {
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 30px;
}

.sidebar-widget-search {
    & form {
        position: relative;
        overflow: hidden;
        border-radius: 4px;

        & input {
            padding-right: 50px;
        }

        & button {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            color: $primary;
            border: none;
            border-radius: 4px;
            background-color: transparent;

            &:hover {
                color: $white;
                background-color: $primary;
            }
        }
    }
}


.sidebar-widget-cate-list {
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
        & + li {
            border-top: 1px solid #EEEEEE;
        }

        & a {
            color: $secondary;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.78;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0 15px 0px;
            &:hover {
                color: $primary;
            }
        }
    }
}

.sidebar-widget-list-post {
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
        padding: 16px 0 15px 0px;
        & + li {
            border-top: 1px solid #EEEEEE;
        }
        span {
            a {
                color: inherit;
                font-size: 16px;
                font-weight: 400;
                text-transform: capitalize;
            }
        }

        & a {
            color: $secondary;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.4;
            color: $heading-color;
            position: relative;
            display: block;
            // Responsive
            @media #{$extra-small-mobile}{
                font-size: 16px;
                line-height: 22px;
            }
            &:hover{
                color: $primary;
            }
        }
    }
}


.tagcloud {
    margin: -5px;
    & a {
        font-size: 14px !important;
        font-weight: 400;
        line-height: 22px;
        font-family: $link-font;
        display: block;
        float: left;
        overflow: hidden;
        margin: 5px;
        padding: 7px 8px 6px;
        color: $primary;
        border: 0;
        border-radius: 3px;
        background: #F5F5F5;

        &:hover {
            color: $white;
            background-color: $primary;
        }
    }
}

.sidebar-widget-banner{
    position: relative;
    z-index: 9;
    padding: 90px 35px;
    text-align: center;
    border-radius: 5px;
    .title{
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 35px;
        color: $white;
    }
    p{
        font-size: 16px;
        line-height: 24px;
        color: $white;
        margin-bottom: 35px;
    }
    a{
        color: $white;
        &:hover{
            color: $primary;
        }
    }
}

.footer-logo{
    margin-bottom: 30px;
}

.footer-widget-title {
    font-size: 18px;
    color: $white;
    margin-bottom: 17px;
}

.footer-widget-content {
    font-size: 18px;
    font-weight: 500;
    color: $gray-100;

    a {
        display: block;
        margin-bottom: 5px;
        font-family: $link-font;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & ul {
        margin-right: -20px;
        margin-bottom: -5px;
        padding-left: 0;
        list-style: none;

        & li {
            margin-bottom: 5px;
            padding-right: 20px;
            a{
                font-size: 16px;
                line-height: 30px;
                // Responsive
                @media #{$desktop-device, $extra-small-mobile}{
                    font-size: 15px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.ft-badge{
    background-color: $primary;
    display: inline-block;
    border-radius: 2px;
    padding: 4px 8px 3px;
    color: $white;
    font-size: 10px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin: 0 0 0 7px;
}

.footer-social-inline {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    & a {
        margin-right: 10px;
        color: $gray-100;

        & i {
            font-size: 24px;
            line-height: 1;
            display: block;
        }

        &:hover {
            color: $primary;
        }
    }
}
