/* 08.03. Blog Details CSS */

.entry-author {
    & .author-info {
        display: flex;
        // Responsive
        @media #{$large-mobile}{
            flex-wrap: wrap;
        }
    }
    & .author-avatar {
        flex-shrink: 0;
        width: 170px;
        text-align: center;
        & img {
            border-radius: 50%;
        }
        // Responsive
        @media #{$large-mobile}{
            text-align: left;
            margin-bottom: 30px;
        }
        @media #{$small-mobile}{
            width: 120px;
        }
    }
    & .author-name{
        font-size: 17px;
        line-height: 24px;
        color: $primary;
        font-weight: 400;
        margin-bottom: 0;
    }
    & .designation{
        font-size: 15px;
        line-height: 24px;
    }
    & .author-biographical-info {
        margin-top: 15px;
        font-size: 15px;
        color: $body-color;
    }
}

.blog-nav-links{
    margin-top: 35px;
    .title{
        display: block;
        font-size: 24px;
        line-height: 1.3;
        font-weight: 700;
        margin-bottom: 40px;
    }
    .nav-list{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 30px;
        // Responsive
        @media #{$large-mobile}{
            grid-template-columns: auto;
        }
        .nav-item{
            .inner{
                a{
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    padding-top: 50px;
                    border-radius: 5px;
                    overflow: hidden;
                    background: $white;
                    padding: 50px 30px 25px;
                    border: 1px solid #e7e7e7;
                    transition: $transition;
                    // Responsive
                    @media #{$desktop-device}{
                        padding: 35px 20px 25px;
                    }
                    .hover-bg{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #333;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: $transition;
                        opacity: 0;
                        &::before{
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            opacity: .5;
                            background-image: linear-gradient(-180deg,rgba(51,51,51,0) 0%,#000 80%);
                            border-radius: 5px;
                        }
                    }
                    .cate{
                        position: relative;
                        display: block;
                        word-wrap: break-word;
                        width: 100%;
                    }
                    h6{
                        position: relative;
                        display: block;
                        word-wrap: break-word;
                        width: 100%;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 1.56;
                        margin: 0;
                    }
                    &:hover{
                        .hover-bg{
                            opacity: 1;
                        }
                        .cate{
                            color: $white;
                        }
                        h6{
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.comment-list-wrapper {
    padding-top: 30px;
    margin-top: 45px;
    .title{
        display: block;
        font-size: 24px;
        line-height: 1.3;
        font-weight: 700;
        margin-bottom: 40px;
    }
}

.comment-list {
    margin: 0;
    padding: 0;
    & .comment {
        list-style-type: none;
        padding: 30px 30px;
        border: 1px solid #e7e7e7;
        margin-bottom: 30px;
        border-radius: 5px;
        // Responsive
        @media #{$extra-small-mobile}{
            padding: 15px 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    & .comment-author {
        float: left;
        & img {
            border-radius: 50px;
        }
        // Responsive
        @media #{$extra-small-mobile}{
            float: none;
        }
    }
    & .comment-content {
        position: relative;
        overflow: hidden;
        margin-left: 100px;
        // Responsive
        @media #{$extra-small-mobile}{
            margin-left: 0;
            margin-top: 15px;
        }
    }
    .meta {
        margin-bottom: 12px;
        & .fn {
            font-size: 15px;
            text-transform: uppercase;
            color: $primary;
            display: inline-block;
            margin-bottom: 0;
        }
        & .comment-datetime {
            position: relative;
            display: block;
            font-size: 14px;
            color: #ababab;
        }
    }
    & .comment-actions a {
        margin-right: 20px;
        font-size: 14px;
        font-weight: 400;
        font-family: $link-font;
        color: $heading-color;
        &:hover {
            color: $primary;
        }
    }
    & .children {
        margin: 20px 0 20px 50px;
        border: 0;
        background-color: #f8faff;
        @media #{$large-mobile} {
            margin: 20px 0 20px 25px;
        }
    }
    & .children li + li {
        margin-top: 35px;
    }
    & .comment-actions {
        margin-top: 16px;
        color: $secondary;
        text-align: right;
        & .comment-reply-link {
            display: inline-block;
            margin-left: 10px;
            font-family: $link-font;
            margin-right: 10px;
            font-weight: 400;
        }
    }
}

.comment-form-wrap{
    margin-top: 60px;
    .comment-respond{
        .title{
            display: block;
            font-size: 24px;
            line-height: 1.3;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
}