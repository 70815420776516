/* 04.03 Breadcrumb CSS */

.breadcrumb {
    padding: 40px 0;
    background-color: transparent;

    // Responsive
    @media #{$large-mobile}{
        padding: 25px 0;
    }

    & li {
        font-size: 16px;
        line-height: 1.5;
        display: flex;
        color: $white;
        &.breadcrumb-item {
            padding-left: 0;
            &.active {
                color: $white;
                text-transform: capitalize;
            }
        }

        & + li {
            &::before {
                margin: 0 15px;
                font-size: 16px;
                content: "\f105" !important;
                font-family: $fontAwesome;
                color: $white !important;
                margin-top: 2px;
                padding-right: 0 !important;
            }
        }

        & a {
            position: relative;
            display: block;
            color: $white;

            &::before {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0;
                height: 1px;
                content: "";
                transition: $transition;
                background-color: $primary;
            }

            &:hover {
                color: $primary;

                &::before {
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
}