/* 04.6 Form CSS */

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"] {
    width: 100%;
    min-height: 56px;
    padding: 3px 20px;
    color: $body-color;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    outline: none;
    background-color: #F5F5F5;
}

textarea{
    width: 100%;
    min-height: 130px;
    padding: 10px 20px;
    color: $body-color;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    outline: none;
    background-color: #F5F5F5;

    &:focus {
        border-color: $primary;
        background-color: $white;
    }
}

.single-input{
    label{
        color: $heading-color;
    }
    .description{
        font-style: italic;
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 0;
    }
}
