/* 06.01. Home One Intro CSS */
.intro-section {
    z-index: 9;
    display: flex;
    align-items: center;
    height: 100vh;
    padding: 50px 0;
    position: relative;

    // responsive
    @media #{$tablet-device} {
        height: 550px;
    }
    @media #{$large-mobile} {
        height: 500px;
    }
}

.intro-content {
    max-width: 760px;
    & .title {
        font-size: 72px;
        line-height: 1.2;
        color: $white;
    }

    & .desc {
        max-width: 700px;

        & p {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.67;
            color: $white;
        }
    }

    & .btn {
        margin: 0 5px;
        margin-top: 60px;
    }

    @media #{$tablet-device, $large-mobile} {
        & .title {
            font-size: 36px;
        }

        & .desc {
            & p {
                font-size: 16px;
            }
        }
        & .btn {
            margin-top: 30px;
        }
    }

    @media #{$extra-small-mobile} {

        & .title {
            font-size: 30px;
        }

        & .desc {
            & p {
                font-size: 14px;
            }
        }
    }
    .vue-typer {
        display: block;
        min-height: 130px;
        @media #{$tablet-device, $large-mobile} {
            min-height: 65px;
        }
        span {
            font-size: 72px;
            line-height: 1.2;
            color: $white;
            margin-bottom: 40px;
            font-weight: 700;
            @media #{$tablet-device, $large-mobile} {
                font-size: 36px;
                margin-bottom: 20px;
            }
            &.caret {
                display: none;
            }
        }
    }
}

.intro-content {
    & > * {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
}

.intro-slider {
    position: relative;
    .main-slider-nav {
        cursor: pointer;
        background: rgba(0,0,0,0.25);
        width: 100px;
        height: 100px;
        line-height: 100px;
        display: block;
        text-align: center;
        color: $white;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        & i {
            font-size: 40px;
            line-height: 1;
            vertical-align: middle;
        }
        &:hover {
            color: $primary;
            background: rgba(0,0,0,0.8);
        }
        // Responsive
        @media #{$desktop-device, $tablet-device, $large-mobile}{
            width: 50px;
            height: 50px;
            line-height: 50px;
            & i {
                font-size: 24px;
            }
        }
        &:after {
            display: none;
        }
    }

    .home-slider-prev {
        left: 30px;
    }
    .home-slider-next {
        right: 30px;
    }
    &:hover{
        .main-slider-nav {
            opacity: 1;
            visibility: visible;
        }
    }
}

.swiper-slide-active {
    & .intro-content {
        // All Element Selector
        & > * {
            animation-name: fadeInUp;
            
            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.5s;
            }
            &:nth-child(2) {
                animation-delay: 1s;
            }
            &:nth-child(3) {
                animation-delay: 1.5s;
            }
            &:nth-child(4) {
                animation-delay: 2s;
            }
            &:nth-child(5) {
                animation-delay: 2.5s;
            }
            &:nth-child(6) {
                animation-delay: 3s;
            }
            
        }
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
  
.fadeInUp {
    animation-name: fadeInUp;
}




