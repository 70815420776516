/*----------------------------------------*/
/*  05. Header CSS
/*----------------------------------------*/

/* Header Section */
.header-section {
    min-height: 100px;
    border-bottom: 1px solid $white-opacity-50;
    // Responsive
    @media #{$tablet-device, $large-mobile}{
        min-height: 80px;
    }

    & .header-inner {
        display: flex;
        align-items: center;
        min-height: 100px;
        background-color: $white;
        // Responsive
        @media #{$tablet-device, $large-mobile}{
            min-height: 80px;
        }
    }
    &.is-sticky {
        & .header-inner {
            position: fixed;
            top: 0;
            left: 0;
            background-color: #030e22 !important;
            width: 100%;
            height: 80px;
            animation: headerSlideDown 0.95s ease forwards;
            box-shadow: 0 8px 20px 0 rgba($black, 0.1);
        }
    }

    // Header Fluid
    &.header-fluid {
        & .container {
            max-width: 100%;

            @media #{$extraBig-device} {
                padding-right: 150px;
                padding-left: 150px;
            }
        }
    }
    &.header-fluid-two {
        & .container {
            max-width: 100%;

            @media #{$extraBig-device} {
                padding-right: 240px;
                padding-left: 240px;
            }
        }
    }

    // Header Shadow
    &.header-shadow {
        & .header-inner {
            box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
        }
    }

    // Header Transparent
    &.header-transparent {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 111;

        & .header-inner {
            background-color: transparent;
        }
    }

    // Header Light
    &.header-light {
        & .header-login {
            & a {
                color: $white;

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

@keyframes headerSlideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@import "header/logo";

/* header Search Area */ 
.header-search-area{
    display: flex;
    align-items: center;
    margin-left: 35px;
}

/* Header Right */
.header-search {
    .header-search-toggle {
        line-height: 1;
        display: flex;
        color: $white;
        background-color: transparent;
        border: none;

        & i {
            font-size: 22px;
            &.fas {
                font-weight: 300;
            }
        }

        &:hover {
            color: $primary;
        }
        @media #{$extra-small-mobile} {
            padding-right: 10px;
        }
    }
}



@import "header/search";
@import "header/main-menu";
@import "header/sidebar-mobile-menu";
@import "header/mobile-menu-toggle";